<template>
   <div class="content">
        <header>
            <h1 class="title has-text-white has-text-weight-light is-size-1 is-size-2-mobile">News</h1>
        </header>
        <main class="section centered-content-75 is-inline-block has-text-left">
            <directus-articles-list :articles="articles" />
        </main>
   </div>
</template>

<script>
import { getTranslatedArticles } from '@/scripts/articles';

import DirectusArticlesList from '@/components/ext/DirectusArticlesList.vue'

export default {
    components: {
        DirectusArticlesList
    },
    data: function() {
        return {
            articles: []
        };
    },
    mounted: function() {
        // Get all the press articles (?filter={"type": {"_eq": "2"}}) in descending order (&sort=-id)
        getTranslatedArticles(this.$i18n.locale, '?filter={"article_type_id": {"_eq": "2"}}&sort=-id')
        .then((result) => {
            this.articles = result;
        });
    }
}
</script>